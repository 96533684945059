import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Typography, Button, Grid, Theme, Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import stationService from 'services/station.service';
import { useStationStore } from 'stores';
import { observer } from 'mobx-react-lite';
import { WashmenIcon } from 'components/washmenIcon';
import { LoaderComponent } from 'components/loaderComponent';
import { getImageSizeInMB } from 'utils/helpers/imageSize';
import { resizeImage } from 'utils/helpers/resizeImage';

import CareLabelAnimation from 'assets/images/pngs/gif/carelabel-animation.gif';

interface Props {
  imageSrc?: string;
  title?: string;
  cancelBtn?: Boolean;
  cancelBtnText?: string;
  isCareLabel?: boolean;
  hideNextButton?: boolean;
  showSkipButton?: boolean;
  handleSkipButton?: () => void;
  onCancelButtonClick?: () => void;
  onPhotoTaken?: (imageBlob: string) => void;
  onConfirmImage?: (imageBlob: string) => void;
  onResetImage?: () => void;
  isReport?: boolean;
  uploadImageBtn?: boolean;
  height?: string;
  isLoader?: boolean;
  currentItemCode?: string;
}

const defaultProps = {
  title: 'Please take a front photo of garment',
  isCareLabel: false,
  onPhotoTaken: (image: string) => {},
  onConfirmImage: (image: string) => {},
  onResetImage: () => {},
  handleSkipButton: () => {},
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: '1',
    },
    photoBoxWrapper: {
      border: '1px solid #b6bcd1',
      padding: '1rem',
      width: 'auto',
    },
    cameraIcon: {
      marginRight: '2rem',
      '& path': {
        fill: 'white',
      },
      width: '2.5vw',
      height: 'auto',
    },
    careLabelAnimation: {
      maxHeight: '10rem',
      maxWidth: '6rem',
      margin: '0 2rem',
    },
  })
);

/**
 *
 * @description PhotoCatcher component that takes photos
 */
const PhotoCatcher: React.FC<React.PropsWithChildren<Props>> = observer(
  ({
    children,
    imageSrc = '',
    cancelBtn = false,
    cancelBtnText = 'cancel',
    onCancelButtonClick = () => {},
    isCareLabel = defaultProps.isCareLabel,
    onPhotoTaken = defaultProps.onPhotoTaken,
    onConfirmImage = defaultProps.onConfirmImage,
    onResetImage = defaultProps.onResetImage,
    handleSkipButton = defaultProps.handleSkipButton,
    title = defaultProps.title,
    hideNextButton = false,
    isReport = false,
    showSkipButton = false,
    uploadImageBtn = false,
    height = '',
    isLoader = true,
    currentItemCode = '',
  }) => {
    const { currentItem } = useStationStore();
    const videoElementRef = useRef<HTMLVideoElement>(null);
    const canvasElementRef = useRef<HTMLCanvasElement>(null);
    const [imgValue, setImgValue] = useState(imageSrc);
    const [loaded, setLoaded] = useState(true);
    const classes = useStyles();

    useEffect(() => {
      if (imgValue) {
        return;
      }
      const video = videoElementRef.current!;
      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then(function (stream) {
            videoElementRef.current!.srcObject = stream;
            // Activate take photo button on load
            setLoaded(false);
          })
          .catch(function (error) {
            console.log('Something went wrong!');
          });
      }
      return () => {
        video.srcObject = null;
      };
    }, [imgValue]);

    async function onTakeScreenShot() {
      try {
        canvasElementRef.current!.width = videoElementRef.current!.videoWidth;
        canvasElementRef.current!.height = videoElementRef.current!.videoHeight;
        canvasElementRef.current!.getContext('2d')!.drawImage(videoElementRef.current!, 0, 0);
        let image = canvasElementRef.current!.toDataURL('image/jpeg', 0.8);
        setImgValue(image);
        const imageSizeMB = getImageSizeInMB(image);

        console.log('Original size:', imageSizeMB, 'MB');

        if (imageSizeMB > 2) {
          image = await resizeImage(image, 320, 240);
        }

        try {
          const url = await stationService.uploadImageToS3(currentItem.code || currentItemCode, image.split(',')[1], isReport);
          if (url) {
            setImgValue(url);
            onPhotoTaken(url);
            return;
          }
        } catch (uploadError) {
          console.error('Initial upload failed:', uploadError);
        }

        // If we're here, it means the initial upload failed
        console.log('Initial upload failed. Resizing and retrying...');

        try {
          const resizedImage = await resizeImage(image, 320, 240);
          const url = await stationService.uploadImageToS3(currentItem.code || currentItemCode, resizedImage.split(',')[1], isReport);

          if (url) {
            setImgValue(url);
            onPhotoTaken(url);
          } else {
            setImgValue(resizedImage);
            onPhotoTaken(resizedImage);
          }
        } catch (resizeUploadError) {
          console.error('Error during resize or second upload attempt:', resizeUploadError);
          // Fallback to saving the original base64 image
          setImgValue(image);
          onPhotoTaken(image);
        }
      } catch (error) {
        console.error('Error onTakeScreenShot:', error);
      }
    }

    function retakeScreenShotHandler() {
      setImgValue('');
      onResetImage();
    }

    function handleConfirmImage() {
      onConfirmImage(imgValue);
    }
    return (
      <RootGrid classes={{ container: classes.root }} height={height} container direction="column" justify="space-around" alignItems="center">
        {isLoader && <LoaderComponent />}
        {!imgValue ? (
          <>
            <Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {isCareLabel && <img src={CareLabelAnimation} alt="careLabel" className={classes.careLabelAnimation} />}
              <Typography variant="h3">{title}</Typography>
              {isCareLabel && <img src={CareLabelAnimation} alt="careLabel" className={classes.careLabelAnimation} />}
            </Grid>
            <Grid item xs={4}>
              <Grid container item alignItems="center" justify="center">
                <video ref={videoElementRef} id="videoElement" autoPlay={true} style={{ width: '100%', maxHeight: '100%' }} />
                <canvas ref={canvasElementRef} style={{ display: 'none' }}></canvas>
              </Grid>
            </Grid>
            <Grid container alignItems="center" justify="space-around">
              {cancelBtn && (
                <>
                  <Button size="large" variant="contained" color="secondary" onClick={() => onCancelButtonClick()}>
                    {cancelBtnText}
                  </Button>
                  <Box margin="0 1rem" />
                </>
              )}

              {showSkipButton && (
                <Button color="secondary" variant="outlined" size="large" onClick={handleSkipButton}>
                  Skip
                </Button>
              )}
              {uploadImageBtn && (
                <>
                  <Button size="large" variant="outlined" color="primary" onClick={() => onCancelButtonClick()}>
                    upload image
                  </Button>
                  <Box margin="0 1rem" />
                </>
              )}
              <Button size="large" variant="contained" color="primary" onClick={onTakeScreenShot} disabled={loaded}>
                <WashmenIcon svg name="cameraIcon" iconClassName={classes.cameraIcon} isButton={false} />
                Take Photo
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid container item classes={{ item: classes.photoBoxWrapper }} justify="center" direction="column" xs={4}>
              <img className="img-responsive" src={imgValue} alt="" />
              <Button color="primary" size="large" variant="text" onClick={retakeScreenShotHandler}>
                Retake Photo
              </Button>
            </Grid>
            <Grid container item direction="row" alignItems="center" justify="center">
              {children}
              {!hideNextButton && (
                <Button color="primary" variant="contained" size="large" onClick={handleConfirmImage} style={{ margin: '0 auto' }}>
                  next
                </Button>
              )}
            </Grid>
          </>
        )}
      </RootGrid>
    );
  }
);

const RootGrid = styled(Grid)<{ height: string }>`
  ${(props) => (props.height ? `height: ${props.height};` : '')}
`;

export default PhotoCatcher;
